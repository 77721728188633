<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <!--<main-img></main-img>-->
                <div class="" style="width: 100%;">
                    <v-row class="sub-title-img-pc" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                주변관광지
                            </h1>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container>
                    <v-row>
                        <v-col  md="6" cols="12"
                                v-for="(data, i) in message"
                                :key="i"
                        >
                            <v-card
                                height="600px">
                                <v-img
                                        :src= data.src
                                        height="300px"
                                ></v-img>
                                <v-card-title>
                                    <div class="mx-auto about-near-item-title my-7">
                                        {{data.title}}
                                    </div>

                                </v-card-title>

                                <v-card-text class="about-near-item-text">
                                    <div v-html="data.text"></div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import MainImg from '../../components/MainImg'
    export default {
        name: 'Near',
        components: {MainImg},
        data: () => ({
            message: [
                {
                    src: require('../../assets/near/near1.jpg'),
                    title: "대둔산-1",
                    text: "충남금산, 논산, 전북완주 2도 3군에 걸처있는 대둔산은 한국8경의 하나로 불려지는 곳이다.<br>" +
                        "기암괴석과 기암절벽이 만들어내는 자연 기암병풍은 중부권의 소금강이라 불려지고있다.<br>" +
                        "금남정맥 줄기가 만경평야를 굽어보면서 솟구쳐 절경을 이룬 곳이다.<br>"


                },
                {
                    src: require('../../assets/near/near2.jpg'),
                    title: "대둔산-2",
                    text: "우뚝 솟은 봉우리마다 독특한 형상이 담긴 대둔산은 잘 다듬어진 조각품에 분재의 군락을 보는 것 같은 수석의 보고이다.<br>" +
                        "올려보든 내려보든 시선이 멈추는 곳은 모두가 아름답고 신비하고 웅장하다.<br>" +
                        "이래서 대둔산을 호남의 금강산이라고 격찬한다."
                },
                /*{
                    src: require('../../assets/near/near3.jpg'),
                    title:"운주사",
                    text:"우리불교의 깊은 혼이 서린 운주사는 우리나라의 여느 사찰에서는 발견할 수 없는 특이한 형태의 불사를 한 불가사의한 신비를 간직하고있다.<br>" +
                        "운주사 서쪽 산능선에는 거대한 두 분의 와불(미완성석불)님이 누워있다.<br>" +
                        "조상 대대로 사람들은 '이 천번째 와불님이 일어나시는 날 새로운 세상이 온다.'는 말을 전해왔다.<br>"
                        /!*"아마도 운주사 천불천탑은 우주법계에 계시는 부처님이 강림하시어 하화중생의 대 설법을 통한 불국정토의 이상세계가 열리기를 간절히 염원하는 마음으로 조성한 대불사가 아닐까한다.",*!/
                },*/
                /*{
                    src: require('../../assets/near/near4.jpg'),
                    title:"운주사 와불",
                    text:"세계에서 하나뿐인 유일한 형태의 와불이다.<br>" +
                        "이는 열반상(부처님이 옆으로 비스듬이 누운 상)과는 다르게 좌불(앉은모습)과 입상(선모습)으로 자연석 위에 조각된 채로 누워있다.<br>" +
                        "이렇게 좌불과 입상의 형태로 누워있는 부처님은 세계에서 하나뿐이라는 것이다.<br>" +
                        "이 부처님은 좌불 12.7미터, 입상 10.26미터의 대단히 큰 불상이다.<br>"
                },*/
                {
                    src: require('../../assets/near/near5.jpg'),
                    title:"태고사",
                    text:"원효대사가 절터를 발견하고 좋아서 3일 밤낮을 춤을 추었다는 전살이 살아있는 천하명당 태고사.<br>" +
                        "만해 한용운 선생님께서는 태고사를 보지않고 천하의 승자를 논하지말라. 하셨을만치 빼어난 곳이다. "
                },{
                    src: require('../../assets/near/near6.jpg'),
                    title:"영주사",
                    text:"황산벌 전투 그리고 영주사<br>" +
                        "영주사는 황산벌전투에서 피를 흘린 영혼들을 위로하기 위해 창건된 절이다." +
                        "신라 화랑도의 으뜸 청년이었단 관창가 백제 계백장군이 황산벌 전쟁을 펼첬던 황산벌 이곳에 영주사가 자리를 잡고있다."
                }
            ]


        }),
        created: function(){
            // 맨위로
            window.scrollTo(0,0);
        },

    }
</script>

<style scoped>

</style>

